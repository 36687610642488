// TODO: Find out why '@src/useApi' doesn't work on these?!?
import { useEffect, useRef, useState } from 'react'
import { get } from '@/src/api'
import { useAppRouter } from '@/src/useAppRouter'
import LoadingOverlay from './LoadingOverlay'


export default function LoginRequired ({children}) {

  const router = useAppRouter()
  const mounted = useRef(false)
  const [loading, setLoading] = useState(true)
  const [redirecting, setRedirecting] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  }, [])


  useEffect(() => {
    checkLogin()

    async function checkLogin () {
      try {
        setLoading (true)

        const res = await get(`/auth/isLoggedIn`)
        const login = await res.json()

        // Avoid making any snap judgments if the API request failed
        if (!login || !login.success) {
          throw Error('API request failed for login check')
        }

        // Now that the API replied, check to see if they are logged in
        if (login.user) {
          const redirect = localStorage.getItem('redirectAfterLogin')
          localStorage.removeItem('redirectAfterLogin')

          if (redirect) {
            try {
              router.push(JSON.parse(redirect))
            } catch (err) {
              console.log("Redirect failed: " + err)
            }
          }
        } else {
          console.log('User is not logged in. Redirecting to login page.')
          setRedirecting(true)

          // Keep track of where they wanted to go, for after a successful login
          if (router.pathname !== '/login') {
            localStorage.setItem('redirectAfterLogin', JSON.stringify({
              pathname: router.pathname,
              query: router.query,
            }))
          }

          router.push('/login')
        }
      } catch (err) {
        console.log('Login check failed (retrying) ' + err)
        setTimeout(checkLogin, 500)
      } finally {
        if (mounted.current) setLoading (false)
      }
    }
  }, [])

  return <>
    <LoadingOverlay visible={loading || redirecting}/>
    {!(loading || redirecting) && children}
  </>
}

